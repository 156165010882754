import React from "react"

if (typeof window !== `undefined`)
  window.location.replace(`https://www.youtube.com/watch?v=x9JGvgrxqhE`)

const FweVideo = () => {
  return (
    <>
      Click to go to{" "}
      <a href="https://www.youtube.com/watch?v=x9JGvgrxqhE">video</a>
    </>
  )
}

export default FweVideo
